<template>
  <div class="w-full">
    <div class="w-full p-4 bg-white rounded">
      <ul class="flex flex-wrap justify-start">
        <li class="md:w-1/3">
          <LinkIcon
            icon="building"
            link-to="Meine Firma"
            description="Bearbeiten Sie Ihre Unternehmensdaten"
            route="/settings/update"
            :permission="permission"
          />
        </li>

        <li class="md:w-1/3">
          <LinkIcon
            icon="user"
            link-to="Benutzer &amp; Gruppenverwaltung"
            description="Verwalten Sie die Benutzer & Gruppen sowie deren Berechtigungen."
            route="/settings/users-and-groups"
            :permission="permission"
          />
        </li>

        <li class="md:w-1/3">
          <LinkIcon
            icon="briefcase"
            link-to="Stammdaten"
            description="Verwalten Sie Ihre Stammdaten für die verschiedene Module"
            route="/settings/general"
            :permission="permission"
          />
        </li>

        <li class="md:w-1/3">
          <LinkIcon
            icon="download"
            link-to="Import / Export"
            description="Importen Sie Ihre bestehenden Daten(z.ß. Kontakte und Produkte) oder exportieren Sie aus bambix für die Weiterverarbeitung in externen Systemsn. (Excel, CSV)"
            route="/settings/import-export"
            :permission="permission"
          />
        </li>

        <li class="md:w-1/3">
          <LinkIcon
            icon="credit-card"
            link-to="Kundenportal"
            description="Verwalten Sie Ihr Abonnment, Bezahlmethode"
            @popup="showModal"
            :permission="permission"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { xhr } from '@/utils/axios'
import LinkIcon from '@/components/Commons/LinkIcon'

export default {
  name: 'settings',
  props: [],
  components: { LinkIcon },
  data() {
    return {
      permission: {
        module: 'Einstellungen',
        subModule: 'Einstellungen',
        childModule: 'Allgemein Einstellung',
        operation: 'edit',
        options: ['All', 'Only own personal data'],
      },
      breadcrumbs: {
        title: 'Allgemeine Einstellungen',
        permission: {
          module: 'Einstellungen',
          subModule: 'Einstellungen',
          childModule: 'Allgemein Einstellung',
          operation: 'edit',
          options: ['All', 'Only own personal data'],
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
    showModal() {
      xhr.post('/client/stripe/customer_portal/').then(response => {
        window.location.href = response.data.customer_portal
      })
    },
  },
}
</script>
